import React from 'react';
import PageTemplateStandardView from './page-templates/standard/view';
import PageTemplateStandardEdit from './page-templates/standard/edit';
import PageTemplateStandardCreate from './page-templates/standard/create';
import PageTemplateStandardIndex from './page-templates/standard';
import PersonnelStandardForm from './forms/PersonnelStandardForm';

import AttendExtension from "./form-extensions/AttendExtension";

import AttendHandler from "./form-extensions/extension-handlers/AttendHandler";

const form_extensions = {
    'attend': AttendExtension,
    'mybe-attend': AttendExtension,
    'public-attend': AttendExtension
};

/**
 * 
 */
const HasAttendExtension = (app_names) => {
    for(var key of Object.keys(form_extensions)) if(Object.keys(app_names).includes(key) && (form_extensions[key] == AttendExtension)) return true;
    return false;
}

/**
 * Will use the above object to map app names from the context.auth.access object to available form extension components
 * Pass this function the context.auth.access object. It will use the module names to decide which components it will return
 * You will then recieve an array of components that you may iterate through.
 * Will always recieve an empty array if no extensions are found so safe to pass to map
 * If you want to make any new extensions remember to import them at the top and then add the 'module name' you wish your extension to be assosiated with. Like if the user in their current auth context has access to attend then they may see the attend personnel options
 */
const RenderExtensions = (app_names) => {
    var extensions = [];
    for(var key of Object.keys(form_extensions)) if(Object.keys(app_names).includes(key)) extensions.push(form_extensions[key])
    return extensions;
}

const form_extension_submit_handlers = {
    'attend': AttendHandler,
    'mybe-attend': AttendHandler,
    'public-attend': AttendHandler
}

/**
 * Counter part to the RenderExtensions function above. This can be called in the same way but instead of returning a JSX component this function will return a different function. The handleSubmit function for the given form above.
 * This should be called as part of your handleSubmit function for the form. Then itterate through all submit handlers so that the data from the extensions may be sent to the correct services depending on the form extension.
 * Works the same as RenderExtension. Pass it the context.auth.access object and it will return an array. This array will be empty if there are no handlers for the apps in the current user context
 */
const ExtensionHandlers = (app_names) => {
    var handlers = [];
    for(var key of Object.keys(form_extension_submit_handlers))
    {
        if(Object.keys(app_names).includes(key)) handlers.push(form_extension_submit_handlers[key])
    }
    return handlers;
}

const mapping = {
    //Add custom form layouts here
}

const RenderComponent = (uuid) => {

    if (mapping[uuid]) return mapping[uuid];
    return PersonnelStandardForm;

}

const page_mapping = {
    "default": {
        "view": PageTemplateStandardView,
        "edit": PageTemplateStandardEdit,
        "create": PageTemplateStandardCreate,
        "index": PageTemplateStandardIndex
    },
    "76635ee3-bb89-4ff4-86aa-b4dfae0887b4": PageTemplateStandardCreate,
    //Add custom form layouts here
}

const RenderPageTemplate = (uuid, type) => {

    if (page_mapping[uuid]) {
        if (page_mapping[uuid][type]) {
            return page_mapping[uuid][type]
        } else {
            return page_mapping['default'][type]
        }
    } else {
        return page_mapping['default'][type];
    }

}

export { HasAttendExtension, RenderComponent, RenderPageTemplate, RenderExtensions, ExtensionHandlers };