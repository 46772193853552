import React, { useContext, useState } from "react";
import { RenderComponent, RenderExtensions } from "../../mapping";
import AuthContext from "../../services/AuthContext";


export default function PersonnelForm(props) {
  const context = useContext(AuthContext);

  const FormComponent = RenderComponent(context.auth.app_uuid);
  const FormExtension = RenderExtensions(context.auth.access);

  return FormComponent && FormExtension ? (
    <div>
      <FormComponent {...props} />
      {
        props.type !== "create" && FormExtension.map((Ext, key) => {
          return (
            <Ext {...props} key={key} />
          )
        })
      }
    </div>
  ) : (
    <p>Loading Form...</p>
  );
}
