import React from 'react';
import { AppHeaderComponent } from "@unity/components";
import PersonnelForm from '../../modules/personnel/PersonnelForm';

export default function PageTemplateStandardCreate(props) {
    return (
        <>
            <AppHeaderComponent
                name={"personnel"}
                subpage={"create"}
                saveBtn={props.handleSubmit}
                theme={props.context.theme}
                history={props.history}
                context={props.context}
            />
            <div>
                <PersonnelForm
                    {...props}
                />
            </div>
        </>
    )
}