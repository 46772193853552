import React, { useEffect, useState } from "react";
import Loading from "../common/Loading";
import { RenderPageTemplate } from "../mapping";
import { PersonnelApi } from "@unity/components";
import { CanRead, CanCreate, CanUpdate } from "../services/Permissions";

export default function PersonnelView({ context, route, history }) {
  const [data, setData] = useState(false);

  const read = CanRead("personnel");
  const create = CanCreate("personnel");
  const edit = CanUpdate("personnel");

  const getPersonnelSingle = async () => {
    const res = await PersonnelApi.getPersonnelSingle(route.match.params.id);
    setData(res.data);
  };

  const handleContactChange = (name, value) => { };

  useEffect(() => {
    getPersonnelSingle();
  }, []);

  const handleChange = (name, value) => { };

  const RenderPage = RenderPageTemplate(context.auth.app_uuid, "view");


  if (data) {
    return (
      <>
        <RenderPage
          data={data}
          handleChange={handleChange}
          type="view"
          handleContactChange={handleContactChange}
          context={context}
          history={history}
          route={route}
          read={read}
          create={create}
          edit={edit}
        />
      </>

    );
  } else {
    return <Loading />;
  }
}
