import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { RenderPageTemplate } from "../mapping";
import { PersonnelApi } from "@unity/components";
import ApiLoader from "../common/ApiLoader";
import { CanCreate } from "../services/Permissions";

export default function PersonnelCreate({ route, context }) {
  const [data, setData] = useState({});
  const [users, setUsers] = useState(false);
  const [manager, setManager] = useState(false);
  const [roles, setRoles] = useState([]);
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState({ status: false });
  let history = useHistory();

  const create = CanCreate("personnel");

  const handleSubmit = async () => {
    setLoading({
      status: !loading.status,
      data: "Saving your record, Please Wait....",
    });

    const x = await PersonnelApi.savePersonnel(data);

    if (x.errors) {
      setLoading({ status: true, data: "Unable to save user permission!, Please Wait...." });
      setTimeout(() => {
        history.push(`/personnel/edit/${data.id}`)
      }, 2000);
    } else {
      setLoading({ status: true, data: "Save Success!, Please Wait...." });
      setTimeout(() => {
        history.push("/personnel/index");
      }, 2000);
    }
  };

  //sets the permission id to the user object

  const handleRoleChange = (id) => {
    const newData = { ...data };
    newData["role_id"] = id;
    const roleObj = roles.find((e) => e.id === id);
    newData["role"] = roleObj.name;
    setData(newData);
  };

  //Calls for fusionauth users not registered for the application

  const getFusionUser = async () => {
    const users = await PersonnelApi.getPersonnelCreateSetup();
    setUsers(users.data.users);
    setRoles(users.data.roles);
  };
  const handleContactChange = (name, value) => {
    const newData = { ...data };
    newData[name] = value;
    setData(newData);
  };

  //places data into a contacts object and user object

  const handleUserSelect = (e) => {
    const user = users[e.target.value];
    const newData = { ...data };
    newData["id"] = user.id;
    newData["uuid"] = user.uuid;
    newData["contact_id"] = user.contact_id;
    newData["name"] = user.name;
    newData["tel"] = user.tel;
    newData["mobile"] = user.mobile;
    newData["email"] = user.email;
    newData["position"] = user.position;
    setData(newData);
  };

  useEffect(() => {
    getFusionUser();
  }, []);

  const RenderPage = RenderPageTemplate(context.auth.app_uuid, "create");

  return (
    <div>

      {users && users.length > 0 ? (
        <>
          <ApiLoader status={loading.status} data={loading.data} />
          <RenderPage
            data={data}
            users={users}
            roles={roles}
            type="create"
            errors={errors}
            handleContactChange={handleContactChange}
            handleSubmit={handleSubmit}
            handleUserSelect={handleUserSelect}
            handleRoleChange={handleRoleChange}
            context={context}
            history={history}
            route={route}
            manager={0}
            create={create}
          />
        </>
      ) : (
        <div className="block">
          <p>You can't add any more users to this system</p>
        </div>
      )}
    </div>
  );
}
