import {AttendanceApi} from "@unity/components";

export default async function(data) {
    let res = await AttendanceApi.updateUserSetup(data.contact_id, data);
    
    //Can NOT use the success flag until the express core is updated to generate it instead of utility!
    if(res.statusCode == 200)
    {
        return {"success": true, name: "attend"}
    }
    else
    {
        return {"success": false, name: "attend"}
    }
}